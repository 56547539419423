import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home_video_section mobile_only">
      <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" id="bg_video_mobile">
        <source src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/mobile-home.mp4" type="video/mp4" />
      </video>
      <h2>Libreng Himala para Sa'yo at Iyong Pamilya</h2>
    </div>
    <div className="home_video_section desktop_only">
      <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" id="bg_video_mobile">
        <source src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/desktop-home.mp4" type="video/mp4" />
      </video>
      <h2>Libreng Himala para Sa'yo at Iyong Pamilya</h2>
    </div>
    <div className="zoom-invite-wrapper">
      <div className="zoom-section">
        <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/zoom-welcome.png" className="welcome-image" />
        <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/zoom-desktop.png" className="welcome-image-desktop" />
        
        <div className="apostle-name mobile_only">
          <div>
            <h4>Apostle Renato D. Carillo</h4>
            <p>
              The Man of God from the Philippines
              <br/>
              God's Endtime Prophet and Apostle
            </p>
          </div>
        </div>
      </div>

      <div className="message">
        <p>Kaibigan,</p>
        <p>Mula sa aking puso ay inaanyayahan kita na makasama sa ating Zoom meeting na gaganapin sa Nov 14-15, 2020 (2pm).</p>
        <p>Ibig ko kayong ipanalangin para sa inyong himala, tulad ng kagalingan sa mga sakit at karamdaman, solusyon sa problema, takot sa virus at marami pang iba.</p>
        <p>Maraming himala ang gagawin ng Diyos sa araw na ito, kaya nais ko na ito ay maranasan mo at ng iyong pamilya.</p>
        <p>Kasabay nito ay ipinagdiriwang din natin ang maluwalhating ika-31 anibersaryo ng Jesus is our Shield Worldwide Ministries.</p>
        <p>Dahil sa limitadong bilang na pwedeng makasama sa ating Zoom meeting, kailangan kayo magpatala/mag-register kaagad upang masigurado ang inyong pagsama.</p>
        <p>
          Nagmamahal,
          <br/>
          Apostle Renato D. Carillo
        </p>
      </div>
    </div>

    <div className="register-instruction">
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSf_txz8eX0bdLFfI2Cd7gH_8fc33dLlm4Fn_n4urQhi82rzfw/viewform?usp=sf_link" className="btn btn-warning btn-register">
        Pindutin para Mag-Register
      </a>
      <p>o tumawag sa ating hotline</p>
      <a href="tel:+63288546796" className="hotline-number">
        (02) 88-546796
      </a>
    </div>

    <div className="message live-message">
      <p>Ang ika-31 taong annibersaryo ng Jesus Is Our Shield Worldwide Ministries ay mapapanood din ng LIVE sa sumusunod na kaparaanan:</p>

      <div className="watch-links">
        <a href="https://www.youtube.com/channel/UCG_nkfwsIhO1OiRQUGfdLyw" target="_blank">
          <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/youtube-live.png" alt="Youtube Live" />
        </a>
        <a href="https://www.facebook.com/JIOSWM" target="_blank">
          <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/fb-live.png" alt="Youtube Live" />
        </a>
        <a href="https://himala.ph" target="_blank">
          <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/oras-ng-himala-channel.png" alt="Youtube Live" />
        </a>
      </div>
    </div>

  </Layout>
)

export default IndexPage
